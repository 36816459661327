import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ImagesService } from 'src/app/services/spartan/masters/images.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';

@Component({
  selector: 'app-modal-images',
  templateUrl: './modal-images.component.html',
  styleUrls: ['./modal-images.component.scss']
})
export class ModalImagesComponent implements OnInit {

	imageList: any[] = [];
	loadSave = false
	//arreglo para las imagenes que se seleccionaron
	selectedImages: any[] = [];
	constructor(
		private imagesService: ImagesService,
		private errorService: ErrorManagementService,
		public dialogRef: MatDialogRef<ModalImagesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { 
		this.selectedImages = data.selectedImages || [];
	}

	ngOnInit(): void {
		this.getImages()
	}

	getImages(){
		this.imagesService.getImages({}).subscribe({
			next: (res: any[]) => {			
				this.imageList = res.filter(image => image !== null)
				this.imageList.forEach(image => {
					image.selected = this.selectedImages.includes(image._id)
				})
			},
			error: (error) => { 
				this.errorService.getErrorMessage(error)
			}
		})
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// selectImage(image) {
		
	// 	this.loadSave = true
	// 	console.log(image);
	
	// 	let send = {
	// 	_id: image._id
	// 	}
	
	// 	this.loadSave = false
	// 	this.dialogRef.close(send)
	// }

	selectImage(image){
		if (!image || !image._id) return;
		const index = this.selectedImages.findIndex(selected => selected._id === image._id);
  
		//si ya esta seleccionada, se podra deseleccionar la imagen
		if(index !== -1){
			this.selectedImages.splice(index, 1);
		}
		else{
			this.selectedImages.push(image);
		}
		
	}

	imgSelected(image: any): boolean {
		return this.selectedImages.some(selected => selected._id === image._id);
	  }

	saveImages(): void{
		//para el staff
		if(this.data.context === 'staff'){
			this.dialogRef.close(this.selectedImages.map(img => ({ image: img, imageUrl: img.url })));
		}
		// //para el gym
		else if(this.data.context === 'gym'){
        this.dialogRef.close(this.selectedImages); 
		}
	}
}
