import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.HOME.DASHBOARD',
    moduleName: 'dashboard',
    icon: 'fas fa-home',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'home/dashboard/main',
        title: 'SpartanGym',
        moduleName: 'dashboard',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MODULES',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MASTERS',
    moduleName: 'masters',
    //permission: ['READ_C_MST_AGENCY'],
    icon: 'fas fa-tools',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/',
        title: 'MENUITEMS.MAIN.COMPANY',
        moduleName: 'company',
        //permission: [],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'spartan/masters/company/list-company',
            title: 'MENUITEMS.MAIN.LISTCOMPANY',
            moduleName: 'company',
            //permission: ['READ_SYS_CNF_PERMISSION'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
         /*  {
            path: 'spartan/masters/company/images',
            title: 'MENUITEMS.MAIN.IMAGES',
            moduleName: 'company',
            //permission: ['READ_SYS_CNF_COMPONENT'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'spartan/masters/company/vimeos',
            title: 'MENUITEMS.MAIN.VIMEOS',
            moduleName: 'company',
            //permission: ['READ_SYS_CNF_PERMISSION'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }, */
          {
            path: 'spartan/masters/company/library',
            title: 'MENUITEMS.MAIN.LIBRARY',
            moduleName: 'company',
            //permission: ['READ_SYS_CNF_COMPONENT'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.STAFF',
        moduleName: 'staff',
        //permission: [''],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'spartan/masters/staff/job-position',
            title: 'MENUITEMS.MAIN.JOB_POSITION',
            moduleName: 'masters',
            //permission: [],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'spartan/masters/staff/staff',
            title: 'MENUITEMS.MAIN.STAFF',
            moduleName: 'masters',
            //permission: [''],
            icon: '',
            class: 'ml-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      }, 
      {
        path: 'spartan/masters/system/goals',
        title: 'MENUITEMS.MAIN.GOALS',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/system/excercise-type',
        title: 'MENUITEMS.MAIN.EXCERCISE_TYPE',
        moduleName: 'masters',
        //permission: [],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/system/muscle',
        title: 'MENUITEMS.MAIN.MUSCLE',
        moduleName: 'masters',
        //permission: [],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/system/variant',
        title: 'MENUITEMS.MAIN.VARIANT',
        moduleName: 'masters',
        //permission: [],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/system/intensity',
        title: 'MENUITEMS.MAIN.INTENSITY',
        moduleName: 'masters',
        //permission: [],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/exercise',
        title: 'MENUITEMS.MAIN.EXERCISE',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/machine',
        title: 'MENUITEMS.MAIN.MACHINE',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/masters/gym',
        title: 'MENUITEMS.MAIN.GYM',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/report/users',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'report',
        //permission: ['READ_SYS_CNF_PERMISSION'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MARKETING',
    moduleName: 'marketing',
    //permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: 'fas fa-bullhorn',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'spartan/marketing/announcement',
        title: 'MENUITEMS.MAIN.ANNOUNCEMENT',
        moduleName: 'marketing',
        //permission: [],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.COMMERCIAL',
    moduleName: 'commercial',
    //permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: 'fas fa-dumpster',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'spartan/commercial/package',
        title: 'MENUITEMS.MAIN.PACKAGE',
        moduleName: 'commercial',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/commercial/routine',
        title: 'MENUITEMS.MAIN.ROUTINE',
        moduleName: 'commercial',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/commercial/program',
        title: 'MENUITEMS.MAIN.PROGRAM',
        moduleName: 'commercial',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.REPORTS',
    moduleName: 'report',
    //permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: 'fas fa-file',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [      
      {
        path: 'spartan/report/complaint',
        title: 'MENUITEMS.MAIN.COMPLAINT',
        moduleName: 'report',
        //permission: ['READ_SYS_CNF_PERMISSION'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'spartan/report/customer-progress',
        title: 'MENUITEMS.MAIN.CUSTOMER',
        moduleName: 'report',
        //permission: ['READ_SYS_CNF_PERMISSION'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.SYSTEM',
    moduleName: '',
    //permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.CONFIGURATION',
    moduleName: 'manage-users',
    //permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: 'fas fa-cog',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
     /*  {
        path: 'system/manage-users/components',
        title: 'MENUITEMS.MAIN.COMPONENTS',
        moduleName: 'manage-users',
        //permission: ['READ_SYS_CNF_COMPONENT'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/permissions',
        title: 'MENUITEMS.MAIN.PERMISSIONS',
        moduleName: 'manage-users',
        //permission: ['READ_SYS_CNF_PERMISSION'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/groups',
        title: 'MENUITEMS.MAIN.GROUPS',
        moduleName: 'manage-users',
        //permission: ['READ_SYS_CNF_GROUP'],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }, */
      {
        path: 'system/manage-users/role',
        title: 'MENUITEMS.MAIN.ROLE',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/user',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'masters',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  
];
