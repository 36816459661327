import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ImagesService } from 'src/app/services/spartan/masters/images.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';

@Component({
  selector: 'app-modal-images-publish',
  templateUrl: './modal-images-publish.component.html',
  styleUrls: ['./modal-images-publish.component.scss']
})
export class ModalImagesPublishComponent implements OnInit {

	imageList: any[] = [];
	loadSave = false

	//arreglo para las imagenes que se seleccionaron
	selectedImages: any[] = [];

	constructor(
		private imagesService: ImagesService,
		private errorService: ErrorManagementService,
		public dialogRef: MatDialogRef<ModalImagesPublishComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { 
	}

	ngOnInit(): void {
		this.getImages()
	}

	getImages(){
		this.imagesService.getImages({}).subscribe({
			next: (res: any[]) => {
				console.log("res img", res);
				
				this.imageList = res.filter(image => image !== null)
				this.imageList.forEach(image => {
					image.selected = this.selectedImages.includes(image._id)
				})
			},
			error: (error) => { 
				this.errorService.getErrorMessage(error)
			}
		})
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// selectImage(image) {
		
	// 	this.loadSave = true
	// 	console.log(image);
	
	// 	let send = {
	// 	_id: image._id
	// 	}
	
	// 	this.loadSave = false
	// 	this.dialogRef.close(send)
	// }

	selectImage(image){
		if (!image || !image._id) return;
		const index = this.selectedImages.findIndex(selected => selected === image._id)	
		//si ya esta seleccionada, se podra deseleccionar la imagen
		if(index !== -1){
			this.selectedImages.splice(index, 1);
		}
		else{
			this.selectedImages.push(image);
		}

	}

	saveImages(): void {
		const selectedImagesData = this.imageList.filter(image => this.selectedImages.includes(image._id));
		this.dialogRef.close(selectedImagesData.length > 0 ? selectedImagesData[0] : null); 
	}
	
}
