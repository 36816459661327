import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
import { FormImageComponent } from '../form-image/form-image.component';
import { ImageS3Service } from 'src/app/services/spartan/image-s3.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';

@Component({
  selector: 'app-table-images',
  templateUrl: './table-images.component.html',
  styleUrls: ['./table-images.component.scss']
})
export class TableImagesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'imageUrl',
    'action'
  ];

  dataSource = new MatTableDataSource<any>([])
  loadData = false
  loadSave = false

  // Formulario para lista de imagenes
  formImage: string = null

  constructor(
    private imageS3Service: ImageS3Service,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private errorService: ErrorManagementService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TableImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getList(){
    this.loadData = true
    setTimeout(() => {     
      this.dataSource.data = this.data.images ? this.data.images.map(element =>{
        return {image: null, imageUrl:element}
      }) : []

      this.loadData = false
    }, 1000);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  resetFormImage() {
    this.formImage = null
  }

  deleteImage(index: number) {
    let auxData = this.dataSource.data.slice()
    this.deleteImageS3(auxData[index].imageUrl)
    auxData.splice(index, 1)
    this.dataSource.data = auxData
    this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
    this.cdr.detectChanges()
  }

  openFormImage(element, position?) {
    const dialogRef = this.dialog.open(FormImageComponent, {
      width: "500px",
      data: element,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == "Close") {
      } else {
        if (result == 'success') {
        } else {
          let data = new FormData()
          data.append("image", result.image)
          this.loadSave = true

          this.imageS3Service.setImage(data).subscribe((res: any) => {
            this.addImage({image: result.image, imageUrl: res.imageUrl}, position)
            this.loadSave = false
            this.snackBarService.success({ message: res.message}) //this.translate.instant('TRANSLATE.SUCCESS_ACTION')
          }, error => {
            this.loadSave = false
            this.errorService.getErrorMessage(error)
          })
        }
      }
    });
  }
  
  addImage(result, position?){
    if(position == undefined){
      let auxData = this.dataSource.data.slice()
      auxData.push(result)
      this.dataSource.data = auxData
      this.resetFormImage()
      this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
      this.cdr.detectChanges()
    }else{
      let auxData = this.dataSource.data.slice()
      auxData.splice(position, 1, result)
      this.dataSource.data = auxData
      this.resetFormImage()
      this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
      this.cdr.detectChanges()
    }
  }

  deleteImageS3(imageUrl){
    let data = {
      fileName: this.imageS3Service.getImageNameByUrl(imageUrl)
    }

    this.imageS3Service.deleteImage(data).subscribe((res: any) => {
    }, error => {
      this.errorService.getErrorMessage(error)
    })
  }

  onSubmit(){
    if(this.dataSource.data.length == 0) {
      return
    }

    this.loadSave = true

    let listImages = this.dataSource.data.map(img => img.imageUrl);

    this.loadSave = false
    this.dialogRef.close(listImages)
  }
}