import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmPasswordValidator } from 'src/app/utils/confirm-password.validator';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  hide_old = false // old password
  hide = false //password
  hide_confirm = false  //confirm password

  public form: FormGroup
  user$: any = null
  userProfile = null

  obs: Subscription[] = []
  loadSave = false

  constructor(
    private fb: FormBuilder, 
    private errorService: ErrorManagementService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private translate: TranslateService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user$ = this.authService.currentUserValue ? this.authService.currentUserValue.user : null
    if(this.user$){
      this.userProfile = this.user$._profile || this.user$.profile;
    }

    this.form = this.fb.group({
      oldPassword: ['', [
        Validators.required, 
        Validators.minLength(3),
        Validators.maxLength(60)
      ]],
      password: ['', [
        Validators.required, 
        Validators.minLength(3),
        Validators.maxLength(60)
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(60)
      ]],
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    })
  }

  ngOnInit(): void {}

  onSubmit() {
    if (!this.userProfile || !this.userProfile.data || !this.userProfile.data.email) {
      this.snackBarService.error({ message: this.translate.instant('MESSAGE.WITHOUT_INFO')})
      return;
    }

    this.loadSave = true
    setTimeout(() => {
      this.authService.changePassword(this.userProfile.data.email)
      this.loadSave = false
      this.onClose()
    }, 150);

    /* if (!this.form.valid) {
      return
    }

    let send = {
      id: this.form.value.id,
      oldPassword: this.form.value.oldPassword,
      password: this.form.value.password
    }

    this.loadSave = true
    this.userService.changePassword(send).subscribe(res => {
      this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
      this.dialogRef.close('success')
      this.loadSave = false
    }, error => {
      this.loadSave = false
      this.errorService.getErrorMessage(error)
    }) */
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
