<div>
    <div class="d-flex justify-content-between">
        <h2 mat-dialog-title>{{ 'TRANSLATE.LIST' | translate }} {{ 'TRANSLATE.OF' | translate }} {{ 'TRANSLATE.IMAGES' | translate }} </h2>

        <div style="display: flex;">
          <!--   <form id="demo-2">
                <input class="input_search" type="search" (keyup.enter)="applyFilter($event.target.value)"
                    placeholder="{{'TRANSLATE.SEARCH' | translate}}"
                    matTooltip="{{'TRANSLATE.SEARCH_INITIAL' | translate}}">
            </form> -->
            <button mat-icon-button color="primary" (click)="openFormImage(null)" matTooltip="{{'TRANSLATE.ADD' | translate}}">
                <mat-icon aria-label="label">add</mat-icon>
            </button>
        </div>
    </div>

    <div>
        <div class="modal-form-body">
            <div class="row w-100">
                <div class="col-md-12 mb-2 container-table">
                    <table mat-table #table [dataSource]="dataSource" class="table-detail w-100" matSort matSortDisableClear multiTemplateDataRows>
                        <ng-container matColumnDef="imageUrl">
                            <th mat-header-cell *matHeaderCellDef>{{'TRANSLATE.IMAGE' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <img class="image_url" [src]="element.imageUrl" onerror="this.src='assets/images/logo_sg.png';" width="200" height="200"  alt="">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex;">
                                <button mat-icon-button color="warn" (click)="deleteImage(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div *ngIf="this.dataSource.data.length === 0" class="mat-table-message">{{'TRANSLATE.ZERO_RECORDS' | translate}}
                    </div>
                        
                    <ngx-loading [show]="loadData"></ngx-loading>
                </div>
            </div>
        </div>
    </div>
    
    <div class="btn-sec d-flex justify-content-between mt-3">
        <button type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>

        <div class="button-container">
            <div *ngIf="loadSave" class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()"
                [disabled]="loadSave">{{ 'TRANSLATE.SAVE' | translate}}</button>
        </div>
    </div>
</div>