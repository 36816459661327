// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'https://us-east-1.aws.data.mongodb-api.com/app/data-qyvap/endpoint/data/v1/',
  domainS3: 'https://csm-dev.albatrosvirtual.com:91/',

  url_amazon_s3: 'https://recruiterfiles.s3.us-east-2.amazonaws.com/folder',
  googleApiKey: 'AIzaSyCGLaRHOp4nNbUu2zi3757OGTatpLadT9w',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
