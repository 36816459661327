<div class="container">
    <div class="d-flex justify-content-between align-items-baseline">
        <h3 mat-dialog-title>{{'AUTH.CHANGE_PASSWORD' | translate}}</h3>
        <div class="card-toolbar">
            <button mat-icon-button color="warn" (click)="onClose()">
                <i class="fas fa-times-circle"></i>
            </button>
        </div>
    </div>
    <div mat-dialog-content class="text-center">
        <div *ngIf="loadSave" class="spinner-progress">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <div *ngIf="!loadSave" class="clearfix row">
            <div class="col-lg-12 change_shape">
                {{'MESSAGE.CHANGE_PASSWORD' | translate}}
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center mt-1">
        <button mat-raised-button [disabled]="loadSave" mat-button (click)="onSubmit()" color="primary">
            {{ 'TRANSLATE.OK' | translate}}
        </button>
    </div>
</div>
  
<!-- <div>
    <div class="d-flex justify-content-between">
        <h2 mat-dialog-title>{{'AUTH.CHANGE_PASSWORD' | translate}}</h2>
    </div>

    <div>
        <form [formGroup]="form">
            <div class="modal-form-body">
                <div class="row w-100">
                    <div class="col-md-12 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'AUTH.CURRENT_PASSWORD' | translate}}</mat-label>
                            <input matInput type="password" formControlName="oldPassword" placeholder="" [type]="!hide_old ? 'password' : 'text'">
                            <mat-icon style="cursor: pointer;" color="secondary" matSuffix (click)="hide_old = !hide_old" inline="true">{{!hide_old ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="form.get('oldPassword').hasError('required')">
                              <strong>{{ 'TRANSLATE.REQUIRED_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('oldPassword').hasError('minlength')">
                              <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('oldPassword').hasError('maxlength')">
                              <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 60</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 pt-2 mb-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'AUTH.NEW_PASSWORD' | translate}}</mat-label>
                            <input matInput type="password" formControlName="password" placeholder="" [type]="!hide ? 'password' : 'text'">
                            <mat-icon style="cursor: pointer;" color="secondary" matSuffix (click)="hide = !hide" inline="true">{{!hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="form.get('password').hasError('required')">
                              <strong>{{ 'TRANSLATE.REQUIRED_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('password').hasError('minlength')">
                              <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('password').hasError('maxlength')">
                              <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 60</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 pt-2 mb-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'AUTH.CONFIRM_PASSWORD' | translate}}</mat-label>
                            <input matInput type="password" formControlName="confirmPassword" placeholder="" [type]="!hide_confirm ? 'password' : 'text'">
                            <mat-icon style="cursor: pointer;" color="secondary"  matSuffix (click)="hide_confirm = !hide_confirm" inline="true">{{!hide_confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="form.get('confirmPassword').hasError('required')">
                            <strong>{{ 'TRANSLATE.REQUIRED_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('confirmPassword').hasError('minlength')">
                            <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('confirmPassword').hasError('maxlength')">
                            <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 60</strong>
                            </mat-error>
                            <mat-error *ngIf="form.get('confirmPassword').errors && form.get('confirmPassword').errors.ConfirmPassword">
                            <strong>{{ 'AUTH.VALIDATION.MATCH_PASSWORD' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="btn-sec d-flex justify-content-between mt-3">
                <button type="button" (click)="onClose()" mat-button >{{ 'TRANSLATE.CANCEL' | translate}}</button>

                <div class="button-container">
                    <div *ngIf="loadSave" class="spinner-container">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    <button class="msr-3" mat-raised-button color="primary" 
                        [disabled]="!form.valid || loadSave" (click)="onSubmit()">{{ 'TRANSLATE.SAVE' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div> -->