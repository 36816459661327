import { Component } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './core/service/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currentUrl: string;

  subscribes = []

  constructor(
    private authService: AuthService,
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    /* this.subscribes.push(this.authService.currentUser.subscribe(data => {
      if(data && data.user) {
        this.permissionsService.flushPermissions();

        // Arreglo con todos los permisos
        for (let item of data.user.groups) {
          for (let aux of item.permissions) {
            this.permissionsService.addPermission(aux.name)
          }  
        }
      }
    })) */
  }

  ngOnDestroy() {
    this.subscribes.forEach(element => {
       element.unsubscribe()
    });
  }
}
