import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MaterialModule } from './material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { SortManuallyComponent } from './sort-manually/sort-manually.component';
import { ZoomImageComponent } from './zoom-image/zoom-image.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule } from 'ngx-loading';
import { MatTableModule } from '@angular/material/table';

import { OwlModule } from 'angular-owl-carousel';
import { ModalImagesComponent } from './modal-images/modal-images.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LocationGymComponent } from './location-gym/location-gym.component';

import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormImageComponent } from './form-image/form-image.component';
import { TableImagesComponent } from './table-images/table-images.component';
import { ModalImagesPublishComponent } from './modal-images-publish/modal-images-publish.component';

@NgModule({
  declarations: [
    ModalImagesComponent,
    LocationGymComponent,
    ChangePasswordComponent,
    CustomDialogComponent,
    SortManuallyComponent,
    ZoomImageComponent,
    FormImageComponent,
    TableImagesComponent,
    ModalImagesPublishComponent
  ],
  imports: [
    SwiperModule,
    MatSliderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    OwlModule,
    CarouselModule,
    BrowserAnimationsModule,
    DragScrollModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxLoadingModule,
    MatTableModule
  ],
  entryComponents: [
    CustomDialogComponent,
    ModalImagesComponent,
    LocationGymComponent,
    SortManuallyComponent,
    ZoomImageComponent,
    FormImageComponent,
    ModalImagesPublishComponent
  ],
  exports: [
    SwiperModule,
    MatSliderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    CustomDialogComponent,
    LocationGymComponent,
    ModalImagesComponent,
    SortManuallyComponent,
    ModalImagesPublishComponent
  ]
})
export class SharedModule {}
