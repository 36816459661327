import { Observable, from } from "rxjs";
import { PaginateOptions } from "./interfaces/paginate-options.interface";
import { PaginateResult } from "./interfaces/paginate-result.interface";
import { BSON } from "realm-web";

export abstract class AbstractRealmRepository<TResult> {
  protected collecion: Realm.Services.MongoDB.MongoDBCollection<any>;

  constructor(
    private readonly collection: Realm.Services.MongoDB.MongoDBCollection<any>
  ) {
    this.collecion = collection;
  }

  findOne(
    filter: Realm.Services.MongoDB.Filter,
    options?: Realm.Services.MongoDB.FindOneOptions
  ): Observable<TResult> {
    return from(this.collection.findOne(filter, options));
  }

  find(
    filter?: Realm.Services.MongoDB.Filter,
    options?: Realm.Services.MongoDB.FindOneOptions
  ): Observable<TResult[]> {
    return from(this.collection.find(filter, options));
  }

  getPaginate(
    filter: Realm.Services.MongoDB.Filter,
    options: PaginateOptions
  ): Observable<PaginateResult<TResult>[]> {
    let extraStages: Record<string, any>[] = [];

    // Búsqueda
    if (options.search) {
      extraStages = [
        { $addFields: { fields: { $objectToArray: "$$ROOT" } } },
        {
          $addFields: {
            matches: {
              $filter: {
                input: "$fields",
                cond: {
                  $and: [
                    options.searchFields && options.searchFields.length > 0
                      ? { $in: ["$$this.k", options.searchFields] } // k es la key
                      : true,
                    {
                      $in: [
                        { $type: "$$this.v" }, // v es el value
                        ["string", "int", "double", "decimal", "date"],
                      ],
                    },
                    {
                      $regexMatch: {
                        input: { $toString: "$$this.v" },
                        regex: options.search,
                        options: "i",
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        { $match: { matches: { $ne: [] } } },
        { $unset: ["fields", "matches"] },
      ];
    }

    // Ordenamiento
    const sort: Record<string, 1 | -1> = {};
    if (options.sort)
      sort[options.sort] = options.sortDirection === "ASC" ? 1 : -1;

    // Paginación
    options.cur_page = options.cur_page || 1;
    options.per_page = options.per_page || 100;

    return from(
      this.collection.aggregate([
        { $match: filter },
        ...extraStages,
        {
          $facet: {
            metadata: [{ $count: "total" }],
            data: [
              { $sort: sort },
              { $skip: (options.cur_page - 1) * options.per_page },
              { $limit: options.per_page },
            ],
          },
        },
      ])
    );
  }

  insertOne(
    document: any
  ): Observable<Realm.Services.MongoDB.InsertOneResult<any["_id"]>> {
    let id = new BSON.ObjectID(BSON.ObjectID.generate());
    document._id = id;
    return from(this.collection.insertOne(document));
  }

  updateOne(
    filter: Realm.Services.MongoDB.Filter,
    update: Realm.Services.MongoDB.Update,
    options?: Realm.Services.MongoDB.UpdateOptions
  ): Observable<Realm.Services.MongoDB.UpdateResult<any["_id"]>> {
    return from(this.collection.updateOne(filter, update, options));
  }

  deleteOne(
    filter: Realm.Services.MongoDB.Filter
  ): Observable<Realm.Services.MongoDB.DeleteResult> {
    return from(this.collection.deleteOne(filter));
  }
}
