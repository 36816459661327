import { Injectable } from "@angular/core";
import { PROVIDER } from "src/environments/config";
import * as Realm from "realm-web";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RealmService {
  private app: Realm.App;
  private database: globalThis.Realm.Services.MongoDBDatabase;

  constructor(private router: Router,) {
    this.app = new Realm.App({ id: PROVIDER.APP_ID });
    if(this.app.currentUser){
      this.database = this.app.currentUser
      .mongoClient("mongodb-atlas")
      .db(PROVIDER.dataBase);
    }else{
      this.router.navigate(['/authentication/signin']);
    }
  }

  getApp() {
    return this.app;
  }

  getCollection(collection: string) {
    return this.database.collection(collection);
  }
}
