<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul id="sidebarnav" class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <!--<li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src="assets/images/usrbig.jpg" class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name"> Emily Smith </div>
            <div class="profile-usertitle-job ">{{'MENUITEMS.USER.POST' | translate}} </div>
          </div>
        </li>-->
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          
          <ng-container *ngIf="!sidebarItem.permission">
            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
              <i [ngClass]="[sidebarItem.icon]"></i>
              <span class="hide-menu">{{sidebarItem.title | translate}}
              </span>
            </a>
          </ng-container>

          <ng-template *ngIf="sidebarItem.permission" [ngxPermissionsOnly]="sidebarItem.permission">
            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
              <i [ngClass]="[sidebarItem.icon]"></i>
              <span class="hide-menu">{{sidebarItem.title | translate}}
              </span>
            </a>
          </ng-template>

          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">

              <ng-container *ngIf="!sidebarSubItem.permission">
                <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                  (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  {{sidebarSubItem.title | translate}}
                </a>
              </ng-container>

              <ng-template *ngIf="sidebarSubItem.permission" [ngxPermissionsOnly]="sidebarSubItem.permission">
                <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                  (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  {{sidebarSubItem.title | translate}}
                </a>
              </ng-template>

              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">

                  <ng-container *ngIf="!sidebarSubsubItem.permission">
                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                      (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                      [ngClass]="[sidebarSubsubItem.class]">
                      {{sidebarSubsubItem.title | translate}}
                    </a>
                  </ng-container>

                  <ng-template *ngIf="sidebarSubsubItem.permission" [ngxPermissionsOnly]="sidebarSubsubItem.permission">
                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                      (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                      [ngClass]="[sidebarSubsubItem.class]">
                      {{sidebarSubsubItem.title | translate}}
                    </a>
                  </ng-template>
                  
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
