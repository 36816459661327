import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-location-gym',
  templateUrl: './location-gym.component.html',
  styleUrls: ['./location-gym.component.scss']
})
export class LocationGymComponent implements OnInit {

  // map
  location: any = {
    lat: 0,
    lng: 0
  }

  constructor(
    public dialogRef: MatDialogRef<LocationGymComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if(Array.isArray(this.data.coordinates)){
      this.location.lng = this.data.coordinates[0]
      this.location.lat = this.data.coordinates[1]
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
