import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-image',
  templateUrl: './form-image.component.html',
  styleUrls: ['./form-image.component.scss']
})
export class FormImageComponent implements OnInit {

  public form: FormGroup
  public id: any = null
  loadSave = false

  file: any = null // auxiliar del archivo
  imageUrl: any = null // url de la imagen

  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<FormImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      image: ['',  Validators.compose([
        Validators.required,
      ])],
    })
  }

  ngOnInit() {
    console.log("aaa", this.data)
    this.initForm()
  }

  initForm() {
    if (this.data == null) {
      this.id = null
    }
    else {
      this.id = this.data.imageUrl
      this.form.setValue({
        image: this.data.image,
      })
      this.imageUrl = this.data.imageUrl
    }
  }

  onSubmit() {
    this.loadSave = true

    let send = {
      image: this.form.value.image,
      imageUrl: this.imageUrl,
    }

    this.loadSave = false
    this.dialogRef.close(send)
  }

  onChange(file: File) {
    if (file) {
      /* const fileSize = file.size / 1024 / 1024; // En MB
      if(fileSize > 1) {
        this.snackBarService.error({ message: this.translate.instant('TRANSLATE.FILE_MAX_SIZE') + `: 1MB`})
        return
      } */

      // Se reemplaza los espacios por subguión
      var blob = file.slice(0, file.size, file.type);
      let newName = file.name.replace(/ /g, '_');
      newName = newName.replace('+', '')
      newName = newName.replace('.exe', '').replace('exe', '')
      const randomName = Array(7).fill(null).map(() => Math.round(Math.random() * 16).toString(16)).join('');
      
      let newFile = new File([blob], `${randomName}_${newName}`, {type: file.type});

      this.file = newFile;
      this.form.get('image').setValue(newFile)
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = event => {
        this.imageUrl = reader.result;
      };
    }
  }

  clearImage() {
    this.file = null
    this.imageUrl = null
    this.form.get('image').setValue(null)
  }

  onClose(): void {
    this.dialogRef.close();
  }
}