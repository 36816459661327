import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  hide = false //password

  loginForm: FormGroup;
  submitted = false;
  error = '';

  obs: Subscription[] = []
  token = null
  tokenId = null

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService, 
  ) {
    this.obs.push(
      this.route.queryParams.subscribe(params => {
        if(params['token']){
          this.token = params['token']
        }
        if(params['tokenId']){
          this.tokenId = params['tokenId']
        }
      })
    )
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', [
        Validators.required, 
        Validators.minLength(3),
        Validators.maxLength(60)
      ]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    this.error = '';

    if (this.loginForm.invalid) {
      return;
    } else {
      const result = await this.authService.resetPassword(this.loginForm.value.password, this.token, this.tokenId);
      if (result.success) {
        this.snackBarService.success({ message: 'Operación Exitosa'});
        setTimeout(() => {
          this.router.navigate(['/authentication/signin']);
        }, 150);
      } else {
        this.error = result.error.error || 'Error al restablecer la contraseña. Por favor, inténtalo de nuevo';
        //this.snackBarService.error({ message: 'Error al restablecer la contraseña. Por favor, inténtalo de nuevo.' });
      }
    }
  }
}
