import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const URL_API = environment.domainS3

@Injectable({
    providedIn: "root",
})
export class ImageS3Service{

    constructor(public http: HttpClient) {
    }

    getImageNameByUrl(url){
        const matchResult = url.match(/\/img\/(.+)$/);
        if (matchResult && matchResult[1]) {
            return matchResult[1];
        } else {
            return null;
        }
    }

    setImage(data){ //subir y actualizar
        /*
        {
            "image":"Reporte Intur 2024-02-29.xlsx"
        }
        */
        const url_api = URL_API + 'api/upload'
        return this.http.post(url_api, data); //retorna message and imageUrl
    }

    deleteImage(data){
        /*
        {
            "fileName":"Reporte Intur 2024-02-29.xlsx"
        }
        */
        const url_api = URL_API + 'api/delete'
        return this.http.post(url_api, data);
    }
}
