import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home/dashboard', pathMatch: 'full' },
      {
        path: 'home/dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'spartan',
        loadChildren: () => 
          import('./pages/modules/spartan/spartan.module').then(
            (m) => m.SpartanModule
          ),
      },
      {
        path: 'spartan/synchronization',
        loadChildren: () => 
          import('./pages/system/synchronization-system/synchronization-system.module').then(
            (m) => m.SynchronizationSystemModule
          ),
      },
      {
        path: 'system/manage-users',
        loadChildren: () => 
          import('./pages/system/manage-users/manage-users.module').then(
            (m) => m.ManageUsersModule
          ),
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled'  // for one load page, without reload
      // useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
