<div>
  <div class="d-flex justify-content-between">
      <h2 mat-dialog-title>{{'TRANSLATE.LOCATION' | translate}}</h2>
  </div>

  <div>
    <div class="map-container">
      <div class="map-frame">
        
        <agm-map id="map" [latitude]="location?.lat" [longitude]="location?.lng" [zoom]="18">
          <agm-marker [latitude]="location.lat" [longitude]="location.lng" [markerDraggable]="false">
          </agm-marker>
        </agm-map>
  
      </div>
    </div>

    <div class="btn-sec d-flex justify-content-end mt-3">
      <button type="button" mat-raised-button color="primary" (click)="onClose()">{{ 'TRANSLATE.OK' | translate}}</button>
    </div>
  </div>
</div>