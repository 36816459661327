import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../models/user';
import * as Realm from "realm-web";
import { RealmService } from 'src/app/services/realm/realm.service';
import { RolesService } from 'src/app/services/system/manage-users/role.service';
import { UsersSettingService } from 'src/app/services/system/manage-users/users-setting.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private realmService: RealmService,
    //private usersSettingService: UsersSettingService,
    //private rolesService: RolesService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async login(email: string, password: string) {
    try {
      const credentials = Realm.Credentials.emailPassword(email, password);
      const user = await this.realmService.getApp().logIn(credentials);
      console.log("aaaa ojo", user)
      console.assert(user.id === this.realmService.getApp().currentUser.id);
      let userinfo: User = {
        user: user,
        token: user.accessToken
      }

      if (user) {
        const hasGymRole = this.getListAndCheckGymRole(user);
        if (hasGymRole) {
          localStorage.setItem('currentUser', JSON.stringify(userinfo));
          this.currentUserSubject.next(userinfo);
          return user;

        } else {
          console.log('No tiene acceso. Por favor, inicie sesión con otra cuenta.');
        }
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      throw error;
    }
  }
  
  async getListAndCheckGymRole(res): Promise<boolean> {
    try {
      const listRole = null //await this.rolesService.getCb().toPromise();
      if (listRole) {
        if (this.hasGymRole(listRole, res.customData)) {
          return true;
        } else {
          const userRole = null //await this.usersSettingService.getCb(res.id).toPromise();
          return this.hasGymRole(listRole, userRole);
        }
      } else {
        console.log('Lista de roles no disponible.');
        return false;
      }
    } catch (error) {
      console.log('Error al obtener la lista de roles:', error);
      return false;
    }
  }
  
  hasGymRole(listRol, res){
    let find = listRol.find(ele => ele._id == res.role.$oid)
    return find ? true : false
  } 
  
  async changePassword(email){
    await this.realmService.getApp().emailPasswordAuth.sendResetPasswordEmail({ email });
    //await this.realmService.getApp().emailPasswordAuth.callResetPasswordFunction(email, password)
  }

  async resetPassword(password, token, tokenId){
    try {
      await this.realmService.getApp().emailPasswordAuth.resetPassword({
        password: password,
        token,
        tokenId,
      });
      return { success: true };
    } catch (error) {
      return { error };
    }
  }

  async logout() {
    await this.realmService.getApp().currentUser.logOut();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
