<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-lg-4">
      <div class="auth-content p-4 d-flex align-items-center min-vh-100">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div>
                <div class="text-center">
                  <div>
                    <img src="assets/images/logo_sg_black.png" class="rounded-circle"  height="80" alt="Logo" />
                  </div>

                  <h4 class="font-20 mt-4">Restablecer contraseña</h4>
                  <p class="text-muted">Por favor, ingresa una contraseña.</p>
                </div>

                <div class="p-2 mt-5">
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div *ngIf="error" class="alert alert-danger mb-3 mt-0 text-center">{{error}}</div>
                    <div class="form-group auth-form-group mb-4">
                      <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide" class="auth-page-icon">{{!hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <input formControlName="password" class="form-control auth-control"
                          id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                          placeholder="Contraseña" [type]="!hide ? 'password' : 'text'" />
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Campo Requerido</div>
                        <div *ngIf="f.password.errors.minlength">Mínimo tamaño del campo 3</div>
                        <div *ngIf="f.password.errors.maxlength">Máximo tamaño del campo 60</div>
                      </div>
                    </div>

                    <div class="container-login100-form-btn">
                      <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
                        Restablecer
                      </button>
                    </div>

                  </form>
                </div>

                <div class="mt-5 text-center">
                  <p> <a routerLink="/authentication/signin" class="font-weight-medium text-primary">
                    Iniciar Sesión </a> </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="auth-bg">
        <div class="bg-overlay"></div>
      </div>
    </div>
  </div>
</div>
