<div>
  <div class="d-flex justify-content-between">
    <h2 mat-dialog-title>{{ (id == null ? 'TRANSLATE.NEW' : 'TRANSLATE.EDIT') | translate }}</h2>
  </div>
    
  <div>
    <form [formGroup]="form">
      <div class="modal-form-body">
        <div class="row w-100">
          <div class="text-inside col-md-12 mb-2" style="text-align: center;">
            <button mat-button color="warn" (click)="fileInput.click()" [disabled]="loadSave">
              <mat-icon>file_upload</mat-icon>
              {{'TRANSLATE.SELECT_IMAGE' | translate}}
            </button>
            <button *ngIf="this.imageUrl != null" mat-button color="warn" (click)="clearImage()" [disabled]="loadSave"
              matTooltip="{{'TRANSLATE.DELETE_IMAGE' | translate}}">
              <mat-icon>clear</mat-icon>
            </button>

            <input type="file" accept=".png,.jpg,.jpeg,.webp,.svg" name="file" #fileInput (change)="onChange(fileInput.files[0])" style="display:none;" />
            <div style="margin-top: 12px;">
                <img style="height: 150px; cursor: pointer;" [src]="imageUrl || 'assets/images/logo_sg.png'" 
                  (click)="fileInput.click()" />
            </div>
          </div>
        </div>
      </div>
      
      <div class="btn-sec d-flex justify-content-between mt-3">
        <button type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>

        <div class="button-container">
          <div *ngIf="loadSave" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
          <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loadSave">{{ 'TRANSLATE.SAVE' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>